@import "/src/utils/colors.scss";


header {
    color: $white;
    padding: 20px 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;

    .navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 30px 0 30px;

        .navbar-title-responsive {
            width: 100%;
            display: none;
            justify-content: space-around;
            align-items: center;
            border-bottom: 1px solid $main-color;

            .bars {
                svg {
                    width: 30px;
                    height: 30px;
                    filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(139deg) brightness(103%) contrast(105%);
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }

        nav {
            ul {
                list-style: none;
                margin: 0;

                li {
                    display: inline-block;
                    margin-right: 20px;

                    a {
                        color: $white;
                        text-decoration: none;
                        transition: all 0.2s ease;
                        font-size: 1.2em;
                        font-weight: 600;

                        &:hover {
                            color: $main-color;
                        }
                    }

                    &:hover > ul {
                        display: block;
                    }

                    .icon-lang {
                        width: 20px;
                        height: 20px;
                        margin-right: 5px;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    header {
        padding: 0;
        background: $third-color;

        .navbar {
            display: inline-block;
            width: 100%;
            margin: 0;
            padding: 0;

            .name {
                display: none;
            }

            .navbar-title-responsive {
                display: flex;
            }

            nav {
                transition: all 0.2s ease;
                display: none;


                ul {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-direction: column;
                    width: 100%;
                    gap: 20px;
                    padding: 1em;

                    li:active {
                        color: $main-color;
                    }
                }

                &.active {
                    display: flex;
                }
            }

            .bars {
                display: block;
            }
        }
    }
}


@media (max-width: 450px) {
    header {
        .navbar > .navbar-title-responsive > h1 {
            font-size: 1.7em;
        }
    }
}
