@import "/src/utils/colors.scss";

.projects {
    background: $second-color;

    .section-container {
        padding: 2em 0;

        h2 {
            width: 100%;
            text-align: center;
            color: white;
            font-size: 3em;
            margin-bottom: 100px;

            span {
                color: $main-color;
            }
        }

        .project-container {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 2.5em;
            align-items: stretch;
            padding: 2em;


            .project-box {
                border-radius: 2em;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

                .project {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    text-decoration: none;

                    img {
                        width: 175px;
                        margin: 1em;
                    }


                    h4 {
                        font-size: 2em;
                        color: $white;
                        text-align: center;
                        margin: .3em 1em;
                    }

                    p {
                        font-size: 1.2em;
                        color: $white;
                        margin: 2em;
                        text-align: center;
                    }

                    &:hover {
                        transform: scale(1.1);
                        transition: all .5s ease;
                    }
                }
            }
        }
    }
}

@media (max-width: 1050px) {
    .projects > .section-container > .project-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 750px) {
    .projects .section-container > .project-container {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (max-width: 500px) {
    .projects .section-container > .project-container {
        margin: 1em;
        padding: 0;

        .project-box > .project > img {
            width: 150px;
        }
    }
}
