@import "/src/utils/colors.scss";
@import "/src/utils/button.scss";

.skills {
    height: 100vh;
    width: 100%;
    background: $third-color;
    color: $white;

    .section-container {
        h2 {
            width: 100%;
            text-align: center;
            color: white;
            font-size: 3em;
            margin-bottom: 100px;

            span {
                color: $main-color;
            }
        }

        .skills-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            margin-bottom: 100px;
            gap: 30px;

            .skills-box {
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;
                width: 350px;
                height: 350px;
                background: $second-color;
                border-radius: 8px;
                color: $white;
                font-size: 1.5em;
                text-align: center;
                padding: 1em;
                transition: all 0.3s ease-in-out;

                &:hover {
                    border: 3px solid $main-color;
                    transform: scale(1.1);
                }

                svg {
                    width: 100px;
                    height: 100px;
                    margin-bottom: 20px;
                    color: $main-color;
                }
            }
        }
    }
}

@media (max-width: 1280px) {
    .skills {
        height: 100%;
    }
}

@media (max-width: 420px) {
    .skills > .section-container > .skills-container {
        .skills-box {
            height: auto;
            width: auto;
            margin: 1em;
        }
    }
}
