@import "/src/utils/colors.scss";
@import "/src/utils/button.scss";

.about {
    height: 110vh;
    width: 100%;
    background: $second-color;
    color: $white;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 1.5em;


    .left {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .text {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            text-align: left;


            h1 {
                font-size: 4em;
                font-weight: 900;
                margin: 0;
                text-align: left;
            }

            h2 {
                font-size: 2em;
                font-weight: 700;
                margin: 0;
                text-align: left;
            }

            p {
                font-size: 1.3em;
                font-weight: 500;
                text-align: left;
            }

            .job-name {
                color: $main-color;
            }
        }

        .social-medias {
            display: flex;
            justify-content: left;
            align-items: center;
            gap: 20px;
            margin-bottom: 40px;

            a {
                border: 3px solid $main-color;
                border-radius: 50%;
                padding: 1em;
                cursor: pointer;
                transition: all 0.2s ease-in-out;

                svg {
                    width: 30px;
                    height: 25px;
                    filter: invert(89%) sepia(68%) saturate(4963%) hue-rotate(102deg) brightness(107%) contrast(102%);
                }

                &:hover {
                    background: $main-color;
                    box-shadow: 0 0 1em $main-color;

                    svg {
                        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
                    }
                }
            }
        }

        .curriculum-vitae {

            button {
                padding: .9em 1.5em;
            }
        }
    }

    .right {
        img {
            height: 325px;
        }
    }
}

@media (max-width: 1200px) {
    .about > .right {
        img {
            width: 250px;
            height: 250px;
        }
    }
}


@media (max-width: 768px) {
    .about {
        height: auto;
        flex-direction: column;
        padding: .5em;

        .left {
            width: 100%;
            margin-top: 150px;
            padding: 1em;

            .text {
                padding: .5em;

                h1 {
                    font-size: 3em;
                }
            }

            .social-medias {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                a {
                    padding: .6em;
                }

                svg {
                    width: 25px;
                    height: 20px;
                }
            }
        }

        .right {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                height: 250px;
            }
        }
    }
}

@media (max-width: 507px) {
    .about > .left {
        margin-top: 150px;

        .text {
            h1 {
                font-size: 2em;
            }

            h2 {
                font-size: 1.5em;
            }

            p {
                font-size: 1em;
            }
        }

        .social-medias {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            a {
                padding: .6em;
            }

            svg {
                width: 20px;
                height: 15px;
            }
        }

        .curriculum-vitae > a > button {
            padding: .8em 1.3em;
        }
    }
}

@media (max-width: 260px) {
    .about > .right {
        img {
            max-width: 200px;
            max-height: 200px;
        }
    }
}
