@import "/src/utils/colors.scss";
@import "/src/utils/button.scss";

.contact {
    background: $third-color;

    .section-container {
        h2 {
            width: 100%;
            text-align: center;
            color: white;
            font-size: 3em;
            margin-bottom: 100px;

            span {
                color: $main-color;
            }
        }

        .contact-container {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            form {
                width: 60%;

                .form-group {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: row;
                    margin: 1em 0;
                    gap: 1em;

                    input, textarea {
                        width: 100%;
                        padding: 1em;
                        border: none;
                        border-radius: 8px;
                        outline: none;
                        font-size: 1.2em;
                        color: $main-color;
                        background: $second-color;
                        resize: none;
                        font-family: 'Open Sans', sans-serif;
                    }

                    button {
                        padding: .7em 2em;
                        margin-top: 1em;
                    }
                }
            }
        }
    }
}


@media (max-width: 440px) {
    .contact > .section-container > .contact-container > form {
        .form-group {
            width: 100%;

            input, textarea {
                font-size: 1em;
            }
        }
    }
}
